import React, { useEffect, useState, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { FirebaseFunctionsContext } from '../GamesLayout';
import Avatar from '@material-ui/core/Avatar';
import Hidden from '@material-ui/core/Hidden';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import LottieImage from '../LottieImage';
import { Link } from 'gatsby';
import GatsbyLinkedCardActionArea from '../CardActionArea';

const useStyles = makeStyles({
  root: {
    minWidth: 320,
    maxWidth: 345,
  },
  title: {
    fontSize: 14,
  },
});

function AdminUsers(props) {
  const classes = useStyles();
  const [usersResult, setUsersResult] = useState()
  const {functions, httpsCallable} = useContext(FirebaseFunctionsContext)
  useEffect(() => {
    const getUsers = async () => {
      const result = await httpsCallable(functions, 'adminBackend-getUsers')()
      setUsersResult(result.data)
    }
    if (!usersResult && httpsCallable && functions) {
      getUsers()
    }
  }, [usersResult, httpsCallable, functions])
  console.log('usersResult', usersResult)
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h3" component="h1">Users</Typography>
        </Grid>
        <Hidden mdDown>
          <Grid container>
            <Grid item md={1}>
              avatar
            </Grid>
            <Grid item md={3}>
              email          
            </Grid>
            <Grid item md={3}>
              whatsapp       
            </Grid>
            <Grid item md={3}>
              fullName
            </Grid>
            <Grid item md={1}>
              level
            </Grid>
            <Grid item md={1}>
              role
            </Grid>
          </Grid>

        </Hidden>
        {!usersResult && <LottieImage json="die-1.json" />}
        {usersResult && usersResult.users && usersResult.users.map((user, index) => {
          return (
            <Grid container key={`${user.photoURL}${index}`}>
              <Hidden mdDown>
                <Grid item md={1} xs={3}>
                  <Avatar src={user.photoURL} />
                </Grid>
                <Grid item md={3} xs={9}>
                  {user.email}
                </Grid>
                <Grid item md={3} xs={9}>
                  {user.phoneNumber}

                </Grid>
                <Grid item md={3} xs={9}>
                  <Link to={`/app/admin/user/${user.uid}`}>{user.displayName}</Link>
                </Grid>
                <Grid item md={1} xs={3}>
                  {user.customClaims && user.customClaims.level}
                </Grid>
                <Grid item md={1} xs={3}>
                  {user.customClaims && user.customClaims.role}
                </Grid>
              
              </Hidden>
              <Hidden mdUp>
                <Card className={classes.root}>
                  <GatsbyLinkedCardActionArea to={`/app/admin/user/${user.uid}`}>
                  <CardHeader
                    avatar={<Avatar src={user.photoURL} />}
                    title={user.displayName}
                    subheader={user.email}
                  />

                  </GatsbyLinkedCardActionArea>
                  <CardContent>
                    <Typography color="textSecondary" gutterBottom>
                      whatsapp: {user.phoneNumber}
                    </Typography>
                    <Typography variant="h5" component="h2">
                      role: {user.customClaims && user.customClaims.role}
                    </Typography>
                    <Typography variant="h5" component="h2">
                      level: {user.customClaims && user.customClaims.level}
                    </Typography>
                  </CardContent>
                </Card>
              </Hidden>

          </Grid>
  
  
          )          
        })}
      </Grid>

    </>
  )
}

export default AdminUsers
