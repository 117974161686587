import React from "react"
import { Router } from "@reach/router"
import Layout from "../../../components/GamesLayout"
import AdminRoute from "../../../components/Routing"
import AdminMenu from "../../../components/AdminMenu"
import AdminUsers from "../../../components/AdminUsers"
import AdminUser from "../../../components/AdminUser"

const Admin = () => {
  return (
    <Layout displayContact={false} displayDataFiscal={false} withSearch={false} useIndex={true}>
      <Router basepath="/app/admin">
        <AdminRoute path="/">
          <AdminMenu path="/" />
          <AdminUsers path="/users" />
          <AdminUser path="/user/:userId" />
        </AdminRoute>        
      </Router>
    </Layout>
  )
}
export default Admin


// http://localhost:8000/evento/olivos-1657238400000-3cKktgmAcDpgx7gmjfql2d/
// http://localhost:8000/evento/olivos-1663286400000-3cKktgmAcDpgx7gmjfql2d/