import React, { useEffect, useState, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { FirebaseFunctionsContext, FirebaseFirestoreContext } from '../GamesLayout';
import Avatar from '@material-ui/core/Avatar';
import Hidden from '@material-ui/core/Hidden';
import Card from '@material-ui/core/Card';
import { parseGamesData, userGamesRef, mapUserGames } from '../../hooks/useUserGames';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import LottieImage from '../LottieImage';
import { Link } from 'gatsby';
import GatsbyLinkedCardActionArea from '../CardActionArea';
import SearchContext from '../TopBarGames/context';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(20),
    height: theme.spacing(20),
  },
}));

function AdminUser(props) {
  const classes = useStyles();
  const [userResult, setUserResult] = useState()
  const [userGames, setUserGames] = useState()
  const [games, setGames] = useState()
  const { userId } = props
  const search = useContext(SearchContext)
  const {functions, httpsCallable} = useContext(FirebaseFunctionsContext)
  const firestoreLite = useContext(FirebaseFirestoreContext)
  useEffect(() => {
    const getUsers = async () => {
      const result = await httpsCallable(functions, 'adminBackend-getUserByUid')({ uid: userId })
      setUserResult(result.data)
    }
    if (!userResult && httpsCallable && functions) {
      getUsers()
    }
  }, [userResult, httpsCallable, functions, userId])
  useEffect(() => {
    const getUserGames = (f) => {
      const gamesRef = userGamesRef(f, userResult.uid)
      const userGamesResult = f.getDocs(gamesRef)
      parseGamesData(userGamesResult, setUserGames)
    }
    if (userResult && firestoreLite && firestoreLite.collection && !userGames) {
      console.log('firestoreLite', firestoreLite)
      getUserGames(firestoreLite)
    }
  }, [userResult, firestoreLite, userGames])
  useEffect(() => {
    if (userGames && search.searchIndex) {
      const g = mapUserGames(search.searchIndex, userGames) 
      setGames(g)
    }
  }, [userGames, search])
  return (
    <>
      <Box marginTop={2}>
      <Grid container direction='column'>
        {!userResult && <div>loading...</div>}
        {userResult &&
          <>
          <Grid container alignContent='center' alignItems='center' direction="row">
            <Grid item xs={12} md={3}>
              <Box justifyContent="center" display="flex">
                <Avatar src={userResult.photoURL} className={classes.large} />
              </Box>
            </Grid>
            <Grid item xs={12} md={9}>
              <Typography component="h1" variant="h4">
                Usuario: {userResult.displayName}
              </Typography>
              <Typography component="h1" variant="h4">
                email: {userResult.email}
              </Typography>
              <Typography component="h1" variant="h4">
                whatsapp: {userResult.phoneNumber}
              </Typography>
              <Typography component="h1" variant="h4">
                level: {userResult.customClaims && userResult.customClaims.level}
              </Typography>
              <Typography component="h1" variant="h4">
                rol: {userResult.customClaims && userResult.customClaims.role}
              </Typography>
              <Typography component="h1" variant="h4">
                provider Id: {userResult.providerData && userResult.providerData[0] && userResult.providerData[0].providerId}
              </Typography>
              <Typography component="h1" variant="h4">
                creado: {userResult.metadata && userResult.metadata.creationTime}
              </Typography>
              <Typography component="h1" variant="h4">
                última actualización: {userResult.metadata && userResult.metadata.lastRefreshTime}
              </Typography>
              <Typography component="h1" variant="h4">
                último login: {userResult.metadata && userResult.metadata.lastSignInTime}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography component="h4" variant="h5">Juegos</Typography>
              
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="row">
                {games && Object.entries(games).map(([list, entries]) => {
                  return (
                    <Grid key={list}>
                      <Grid container direction="column">
                        <Grid item>
                          <Box m={1}>
                            <Typography component="h4" variant="h5">{list}</Typography>

                          </Box>
                        </Grid>
                        {entries.map((game) => {
                          return (
                            <Grid key={game.id} item>
                              <Box m={0.5}>
                                <Link to={`/ba/juego/${game.id}/${game.slug}`}>
                                  {game.title}
                                </Link>
                              </Box>
                              
                              </Grid>
                          )
                        })}
                      </Grid>
                    </Grid>
                  )
                  })
                }
              </Grid>
            </Grid>
          </Grid>
          </>
        }
      </Grid>

      </Box>

    </>
  )
}

export default AdminUser
