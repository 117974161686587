import React from 'react';
import Grid from '@material-ui/core/Grid'
import Button from '../Button'
function AdminMenu(props) {

  return (
    <>
      <Grid container>
        <Grid item>
          <Button to="/app/admin/users">Users</Button>
        </Grid>
      </Grid>
    </>
  )
}

export default AdminMenu